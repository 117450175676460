import React from 'react'


function Video() {
    return (

          

            <video  muted width="1500" height="1000" controls autoPlay  >
                <source src="./Videos/rickroll.mp4" type="video/mp4" />
            </video>
    
    )
}

export default Video
