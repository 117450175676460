import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Home from './components/Home';
import Video from './components/Video';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>

        <Route path="/desbloquear" element={<Video/>}>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
