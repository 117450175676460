import React from 'react'
import './Home.css'
function Home() {
    return (
        <div className='cont'>
            <h1>Desbloquear juegos</h1>

            <a href="/desbloquear"><button>Twitch</button></a>
            <a href="https://matias.ma/nsfw/"><button>Youtube</button></a>
            <a href="/desbloquear"><button>Roblox</button></a>
        </div>
    )
}

export default Home
